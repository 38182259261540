.login-form {
	max-width: 300px;
}

.login-form-forgot {
	float: right;
}

.login-form-remember {
	float: left;
}

.ant-col-rtl .login-form-forgot {
	float: left;
}

.login-form-button {
	background: var(--probluedarker);
	border-color: var(--probluedarker);
	color: white;
	border-radius: 5px;
}

.login-form-button:focus {
	background-color: #578bbb;
	color: #e4e4e4;
}

.form-div {
	padding-top: 5%;
}

.login-header-style {
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: var(--probluedarker);
	padding-bottom: 15px;
	text-align: left;
}

.forgot-header-style {
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: var(--probluedarker);
	text-align: left;
}

.or-register {
	padding-top: 20px;
}

.card-style {
	background: #FBFBFB;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}

.input-box-style {
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	background: #FFFFFF !important;
	height: 38px !important;
}

.login-logo {
	width: 158px;
	height: 41.24px;
	margin-bottom: 23px;
}

.submit-btn {
	background: var(--probluedarker) !important;
	border-color: var(--probluedarker) !important;
	border-radius: 5px;
	color: white;
}

.submit-btn:focus {
	background-color: #578bbb !important;
	color: white;
}

.submit-btn:hover {
	background-color: #578bbb !important;
	color: white;
}

.ml-4px {
	margin-left: 4px;
}

.forgot-text {
	text-align: left;
	padding-bottom: 15px;
}

.validation-alignment .ant-form-item-explain {
	text-align: left;
}

.Checkbox-prevent-text-wrap {
	display: flex;
	margin-left: 18px;
	text-align: left;
}

.Checkbox-prevent-text-wrap .ant-checkbox {
	margin-left: -20px;
}

.ant-checkbox {
	top: 0.1em;
}