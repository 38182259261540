
.benchmark-field {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
  }
  .benchmark-field .ant-input-number {
    width: 70px;
  }
  .template-form-outer {
    padding: 15px;
  }
  form.ant-form.template-form {
    margin: -15px;
    padding: 4px;
  }
  .ant-collapse.template-collapse,
  .ant-collapse.template-collapse .ant-collapse-item {
    border: none;
  }
  .ant-collapse.template-collapse .ant-collapse-header {
    background: #f7f7f7 !important;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
  }
  .ant-collapse.template-collapse:nth-child(even)  .ant-collapse-header, 
  .edit-criteria-form, 
  .edit-criteria-form .ant-row:nth-child(even) .ant-collapse-content.ant-collapse-content-active {
    background: #E7ECEE !important;
  }
  .ant-collapse.template-collapse .ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .template-collapse .ant-form-item-label > label::after {
    display: none;
  }
  .template-collapse .ant-form-item-label > label {
    padding-right: 10px;
    text-align: right;
    word-break: break-word;
  }
  .pqq-template-collapse .ant-form-item-label > label:not(.pqq-template-collapse .file-div .ant-form-item-label > label) {
    /* padding-right: 10px; */
    text-align: right;
    word-break: break-word;
  }
  .template-collapse .ant-form-item {
    margin-bottom: 0px;
    /* width: 100%; */
  }
  /* .template-collapse textarea {
    resize: none;
  } */
  .file-div .ant-form-item-label label {
    color: #857E7E;
    font-size: 11px;
  }
  .file-div .ant-form-item .ant-upload {
    float: right;
  }
  .ant-col.file-div {
      padding: 5px 0;
  }

  .file-div {
    .ant-form-item-control {
      flex-grow: 0 !important;
      flex: none;
    }
  }

  .pqq-form-upload-buttons {
    .ant-upload {
      float: left !important;
    }
    .ant-upload-list-item-info {
      transition: none;
    }
    .ant-upload-list-text {
      width: 1.5rem;
      .ant-upload-span {
        width: 20rem;
        margin-left: -17rem;
      }
    }
  }
  /* .add-question-outer {
    margin: -7px -15px;
  } */
  .add-question-btn {
    background: #f7f7f7;
    border-radius: 0;
    height: 35px;
    border: none;
  }
  .add-question-btn:hover {
    color: #3471A9;
  }
  .template-collapse .ant-collapse-content,
/*   .template-collapse .gray-bg .ant-collapse-content > .ant-collapse-content-box,
  .ant-collapse.template-collapse {
    background: transparent;
  } */
  .template-collapse .gray-bg .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
  }
  .dynamic-form-outer form {
    margin: 0 -24px 0 -16px;
    padding: 0 24px 0 16px;
  }
  .dynamic-form-outer form .ant-card {
    background: #f7f7f7;
  }
  .template-form-outer .block-btn-3 {
    border: none;
  }
  .question-close-btn {font-size: 18px;margin-top: -15px;margin-right: -15px;}
  .Change-psd-close-btn {
    float: right;
    color: rgb(102, 102, 102);
    position: absolute;
    top: -17px;
    right: -15px;
    font-size: 20px;
  }
  .dynamic-form-outer .ant-card-body {padding: 10px 12px;}
  .add-ques-field .ant-col.add-questn {
    width: calc(100% - 130px);
  }
  .add-ques-field .ant-col.add-quesn-btn {
    width: 130px;
  }
  .template-collapse .dynamic-form-outer .login-form-remember span:not(.ant-checkbox),
  .template-collapse .login-form-remember span:not(.ant-checkbox) {
    float: left;
  }
  .template-collapse .pqq-questn-delete {
    display: none;
  }
  .template-collapse .ant-collapse-item-active .pqq-questn-delete {
    display: block;
  }
  .template-form-outer .custom-scroll {
    margin-right: -25px;
    padding-right: 12px;
    height: calc(100vh - 350px);
  }
  .vendor-score-sectn .custom-scroll {
    margin-right: -7px;
    padding-right: 0px;
    height: calc(100vh - 295px);
    margin-top: 15px;
}
  .template-collapse .ant-checkbox-wrapper:not(.login-form-remember), 
  .template-collapse .ant-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    min-width: 50px;
    justify-content: center;
    text-align: center;
    margin: auto;
    font-size: 11px;
  }
  .template-collapse .ant-checkbox-wrapper:not(.login-form-remember) span.ant-checkbox,
  .template-collapse .ant-radio-wrapper span.ant-radio {
    order: 2;
  }
  .template-collapse .ant-checkbox-wrapper:not(.login-form-remember) span:not(.ant-checkbox),
  .template-collapse .ant-radio-wrapper span:not(.ant-radio) {
    width: 100%;
    order: 1;
    margin-bottom: 3px;
  }
  .fixed-mr {
    margin-right: 133px;
  }
  form.add-criteria-form {
    background: #e7ecee;
    padding: 6px 10px 8px;
    display: flex;
    gap: 15px;
    align-items: flex-start;
  }
  .add-criteria-form .criteria-form-weight input {
    width: 68px;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .add-criteria-form .criteria-form-name {
    width: 70%;
  }
  .add-criteria-form .criteria-form-btn {
    width: 160px;
    padding: 0 !important
  }
  .ant-form-item.criteria-form-weight input::placeholder {
    font-size: 12px;
    text-align: center;
    line-height: 12px;
  }
  .criteria-form-btn .grey-btn-outer,
  form.add-criteria-form .ant-form-item {
    margin-bottom: 0;
  }
  .add-criteria-form .ant-row {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
  .template-collapse .ant-radio-wrapper span.ant-radio {min-width: 16px;}
  .options-field {flex-wrap: nowrap;}
  .options-field .ant-col:not(:last-child) {
    width: 50%;
  }
  .template-collapse .ant-tabs-bar {
    margin-bottom: 15px;
  }
/* PQQ */
.template-collapse .ant-col.Small .ant-col-16 input, .template-collapse .ant-col.Small .ant-select{
  width: 90px;
}
.template-collapse .ant-col.Medium .ant-col-16 input, .template-collapse .ant-col.Medium .ant-select {
  width: 150px;
}
.template-collapse .ant-col.Large .ant-col-16 input, .template-collapse .ant-col.Large .ant-select {
  width: 220px;
}
/* Country */
.template-collapse .ant-col.Small select{
  width: 90px;
}
.template-collapse .ant-col.Medium select{
  width: 150px;
}
.template-collapse .ant-col.Large select{
  width: 228px;
}
/* typography */
.template-collapse .ant-col.Small .ant-col-20 .ant-typography{
  width: 90px;
}
.template-collapse .ant-col.Medium .ant-col-20 .ant-typography {
  width: 150px;
}
.template-collapse .ant-col.Large .ant-col-20 .ant-typography {
  width: 228px;
}
.template-collapse .ant-typography{word-break: break-word}
.template-collapse .ant-input-number{width: unset;}
/* Vendor PQQ start */
.pqq-template-collapse .ant-col.Small .ant-col-19 input, .pqq-template-collapse .ant-col.Small .ant-select{
  width: 90px;
}
.pqq-template-collapse .ant-col.Medium .ant-col-19 input, .pqq-template-collapse .ant-col.Medium .ant-select{
  width: 150px;
}

.pqq-template-collapse .ant-col.Medium input[name*="Client Contact: "] {
  width: 228px;
}

.pqq-template-collapse .ant-col.Medium input[placeholder="Client Email"] {
  width: 228px;
}
.pqq-template-collapse .ant-col.Large .ant-col-19 input, .pqq-template-collapse .ant-col.Large .ant-select{
  width: 100%;
}
/* Country */
.pqq-template-collapse .ant-col.Small select{
  width: 90px;
}
.pqq-template-collapse .ant-col.Medium select{
  width: 150px;
}
.pqq-template-collapse .ant-col.Large select{
  width: 228px;
}
.pqq-template-collapse .ant-input-number{width: unset;}
/* Vendor PQQ end */
.ques-component .ant-form-item-control {
    max-width: 130px;
    width: 130px !important;
}
/* .template-collapse .input-crazy-style .ant-form-item-label > label {
    text-align: right;
} */
.options-field .ant-col.options-input input {
    width: 140px;
    font-size: 12px;
}

.form-item {
  .ant-form-item-label {
    margin-top: -5px;
  }
}

.pqq-number {
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-input:placeholder-shown {
    text-align: left;
  }
}

.template-collapse .options-3 .ant-col.ant-form-item-control {
    width: 350px;
}
.template-collapse .options-3 .ant-col.ant-form-item-label {
    width: calc(90% - 350px);
}
/* .template-collapse .options-2 .ant-col.ant-form-item-control {
    width: 240px;
} */
.template-collapse .options-2 .ant-col.ant-form-item-label {
    width: calc(90% - 240px);
}
/* .options-field .ant-col:not(.options-input) {
    padding: 6px 0 !important;
} */
.template-collapse .options-2 .ant-col.ant-form-item-control .ant-col.options-input {padding-right: 0 !important;}
.options-field {
    margin-top: 10px;
}
.options-field .ant-col.options-input {
    margin-top: 4px;
}
/* .options-field .ant-col.options-input {
    margin-top: 7px;
} */
.template-collapse .options-field .ant-radio-inner {
    width: 12px !important;
    height: 12px;
}
.template-collapse .options-field .ant-radio-inner::after {
    width: 12px;
    height: 12px;
    top: 7px;
    left: 7px;
}

.template-collapse {
  .ant-tabs-top>.ant-tabs-nav {
    margin: 0px;
  }
}

.pqq-template-collapse {
  .ant-tabs-top>.ant-tabs-nav {
    margin: 0px;
  }
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list{
    width: 100%;
  }
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-add, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add {
    position: absolute;
    right: 0px;
    background: #FFFFFF;
    padding: 0;
    bottom: 2px;
    height: 22px;
    width: 11px;
  }
}

.options-2 .ant-col.ant-form-item-label label {
    text-align: left;
    float: none;
}
.ant-row.ques-component {
    margin-right: 125px !important;
    justify-content: space-between;
}
.criteria-component  .ant-col.ant-form-item-label {
    min-width: 59px;
}
.criteria-component  .ant-col.ant-form-item-label label {
    text-align: left;
    float: none;
}
.ant-form-item.add-content-textarea {
    margin-left: 59px;
    padding: 14px 0 0px;
    width: calc(100% - 55px);
}
.ant-form-item.add-content-textarea textarea {
    width: calc(100% - 30px);
    margin-bottom: 3px;
}
.ant-form-item.add-content-textarea .anticon {
    font-size: 14px;
    color: var(--probluedarker);
}
.template-collapse .ant-picker, .boq-modal .ant-picker {
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px !important;
    height: 28px;
}
.boq-modal .ant-picker {width: 100%;}
.ant-card.new-section {
  background: #f7f7f7;
  border: none;
}
.ant-card.new-section .ant-card-body {
  padding: 10px;
  background: #f7f7f7;
}
.ant-card.new-section .ant-card-head {
  padding: 0 10px;
}
.ant-card.new-section .ant-card-head .ant-card-head-title {
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px;
  color: var(--probluedarker);
}
.new-section .ant-form-item {
  margin-bottom: 12px;
}
.new-section .ant-card-body .add-questn-sectn  .ant-form-item {
  margin-bottom: 0;
}
.ant-collapse.template-collapse.pqq-collapse .ant-collapse-header,
.edit-criteria-form .ant-collapse .ant-collapse-header {
  padding-right: 30px;
}
.ant-collapse.template-collapse.pqq-collapse .ant-collapse-header .ant-collapse-extra,
.edit-criteria-form .ant-collapse .ant-collapse-header .ant-collapse-extra {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.benchmark-field .ant-input-number .ant-input-number-input {
  height: 28px;
}
.score-input{
  width: 64px !important;
  height: 24px;
  padding: 4px 4px !important;
  text-align: center;
}
.score-input .ant-input-number-input{height: auto !important; text-align: right;}
.points-input .ant-input-number-input{
  width: 30px !important;
  height: 26px;
}
.action-popover{
  padding-bottom: 0px !important;
}
.action-popover .ant-popover-inner-content{
  padding: 3px 12px;
}
.action-popover .ant-popover-arrow{
  display: none;
}
.icon-rotate{
  -ms-transform: rotate(44deg);
  transform: rotate(44deg);
  position: relative;
  top: -12px;
  left: -9px;
}
.error-text-color{
  font-size: 16px;
  color: #ee2d2d;
}
.triangle-error-action-btn{
  font-size: 26px;
  color: #ee2d2d;
}
.triangle-action-btn{
  font-size: 20px;
  color: var(--problue);
}
.triangle-action-btn:hover,.triangle-action-btn:focus,.triangle-action-btn:active{
  color: #C4C4C4 !important;
}
.dc-flex{display: flex;}
.btn-tl{
  position: absolute;
  left: 0;
  padding: 0;
  height: 15px;
  width: 15px;
}
.cellcomment-tl{position: absolute;top: 0;left: 0;}
.cellcomment-tl .ant-badge-count{left: 0; top: 13px;}
.cellcomment-btn{padding: 0;height: 15px;width: 15px;}
.dc-pts .ant-input-number-input{
  padding: 0 7px;
}
.dc-pts .ant-input[type=number]{
  padding: 0 7px;
}
.dc-pts-width{
  width: 37px !important;
  text-align: center;
}
.dc-pts-width{
  .ant-input-number-input {
    text-align: right;
  }
}
.dc-pts-width-pqq {
  width: 75px;
  text-align: center;
}
.modal-question-card{
  background: rgba(225, 231, 233, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}
.modal-question-card .ant-card-body{
  padding: 14px 25px 36px 18px;
}
.dc-visible .pqq-collapse-scroll, .dc-visible .ant-collapse-content-active{
  overflow: visible !important;
}
.range-inp-width{
  width: 63px !important;
}
.question-label-px label{
  padding: 5px 9px 5px 25px;
}
.dc-dark-label label{
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
  font-weight: unset !important;
}
.dc-flex-start{
  align-items: flex-start;
}
/* .dc-hid-overflow-col-8 .ant-col-8{
  overflow: hidden;
} */