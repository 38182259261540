.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

.btn-success{
  background-color: #44bd44;
  color: white
}

.btn-success:hover{
  background-color: #44bd44;
  color: white
}

.btn-margin{
  margin-left: 13%;
}

.master-outer-card{
  background: #F6F9F9;
  border-radius: 6px;
}
.master-card-text{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--probluedarker);
}
.master-sub-text{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.master-label-text{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--probluedarker);
}
.master-card-input{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 38px;
}
.default-btn-style{
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.master-pr-20{
  padding-right: 20px;
}
.required-style{
  display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
}
.division-label{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.addnew-taxes-dropdown{
  color: var(--probluedarker);
}
.center-object{
  align-items: center;
  text-align: center;
}
.center-object .ant-form-item-control{
  align-items: center;
  text-align: center;
}
.blue-form{
  background: #F6F9F9;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.1);
}
.blue-bg{
  background: #F6F9F9;
}
.mud-bg{
  /* background: rgba(240, 239, 239, 0.5); */
  /* background: #f8f7f7; */
  border: 2px solid #c5c5c5;
  border-radius: 2px;
  min-height: 70vh;
}
.mud-bg-2{border: 2px solid #c5c5c5;border-radius: 2px;}
.h-75vh{min-height: 75vh;}
.faded-blue-bg{
  background: rgba(214, 244, 252, 0.25);
}
/* .gray-bg .ant-collapse-content > .ant-collapse-content-box{
  background: #F0EFEF;
} */
.add-question-btn{
  color: #3471A9;
  background: #EAEEF0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.tab-pannel-alignment{
  padding: 15px;
  margin-top: 17px;
}
.master-tabs .ant-tabs-bar{
  margin: 0 0 7px 0 !important;
}
.master-form-style .ant-form-item-label > label{
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #5F5A60;
}
.master-form-style .ant-form-item-label > label::after{
  content: '' !important;
}
.master-form-style .ant-space-align-center{
  float: unset;
}
.grid-active-left:hover{
  background: #F0EFEF;
  /* box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25); */
  -webkit-box-shadow: 4px 6px 7px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 4px 6px 7px 0px rgba(0,0,0,0.25);
  box-shadow: 4px 6px 7px 0px rgba(0,0,0,0.25);
}
.grid-active-right:hover{
  background: #F0EFEF;
  /* box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25); */
  -webkit-box-shadow: -4px 6px 7px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: -4px 6px 7px 0px rgba(0,0,0,0.25);
  box-shadow: -4px 6px 7px 0px rgba(0,0,0,0.25);
}
.master-col-350 {
  min-width: 353px;
  max-width: 353px;
  /* width: 25%;
  flex: initial; */
}
.master-col {
  width: calc(100% - 353px);
  max-width: inherit;
  flex: initial;
  padding-left: 0 !important;
}
.master-col.masterAuth-col {
  width: 100%;
  padding-left: 10px !important;
}
.master-col-350 .tab-pannel-alignment {
  padding: 1px 0px 15px 12px;
  margin-top: 0;
}
.blue-bg-padding {
  padding: 10px 5%;
}
.master-col-350 .blue-bg-padding {
    max-width: 264px;
    margin: 0 auto;
}
/* .master-col-350 button.ant-btn {
  width: 128px;
} */
.master-btn-outer {
  padding-top: 20px;
}
.ant-form label.master-tab-title {
  font-size: 18px;
  color: #5F5A60;
  font-family: 'Signika Negative';
  padding-left: 15px;
}
.master-justify-between {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 9px;
}
.master-justify-between.master-justify-end {
  justify-content: flex-end;  
}
/* .master-col .master-table-top-btn button.ant-btn {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
} */
.master-col .grey-btn-outer {
  margin-bottom: 0;
}
.master-col .master-table-top-btn .anticon,
.master-table table .anticon {
  font-size: 10px;
}
.master-col-350 .ant-input-number {
  width: 100%;
}
.master-col .tab-pannel-alignment {
  margin-top: 0;
  padding: 0 24px;
}
.master-table table th.ant-table-cell:nth-last-child(2) {
  min-width: 70px;
}
table tr.ant-table-row td {
  padding: 4px 8px;
  height: 33px !important;
}
.master-col .ant-input-search.search-field.ant-input-affix-wrapper {
  margin-top: 1px;
}
.master-col .ant-input-search.search-field.ant-input-group-wrapper {
  margin-top: 1px;
}
.ant-input-search.search-field.ant-input-affix-wrapper .anticon {
  font-size: 20px;
  padding-top: 5px;
}
.ant-input-search.search-field.ant-input-group-wrapper .anticon {
  font-size: 20px;
  padding-top: 0px;
}
.master-proj-list {
  width: 333px;
  /* max-width: 100%;
  margin-bottom: 0; */
  /* min-width: 286px;
  width: 25%; */
  flex: initial;
  /* max-width: 333px; */
}
.master-justify-between .ant-row.ant-form-item,
.master-justify-between .ant-row.ant-form-item .grey-btn-outer {
  margin-bottom: 0;
}
.master-data-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}
.master-proj-list .ant-select-selector {
  height: 30px !important;
}
.master-col-350 .ant-form-item-label > label{
  font-size: 13px;
  min-height: inherit;
}
.master-col-350 .ant-form-item-label {
  padding-bottom: 0;
}
.master-table .ant-space svg path {
  color: #9D9E9E;
  stroke: #9D9E9E;
}
.master-table .ant-space .anticon:hover svg path {
  color: var(--probluedarker);
  stroke: var(--probluedarker);
}
.master-table .ant-space.Inactive .anticon:hover svg path {
  color: #9D9E9E;
  stroke: #9D9E9E;
}
.active-btn-outer button.ant-btn.grey-btn:first-child {
  padding-right: 10px;
}
.active-btn-outer button.ant-btn.grey-btn:last-child {
  padding-left: 10px;
}
.master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
  padding: 6px 12px;
  margin-right: 3px;
}
/* .ant-tabs.master-data-tabs.ant-tabs-card {
  margin-top: -8px;
} */
.ant-tabs.master-data-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll {
  text-align: right;
}
.master-col .master-table-outer .ant-form-item-control-input-content {
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.ant-row.master-row {
  display: flex;
  flex-wrap: nowrap;
}

.specification {
  .ant-row.master-row {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 0.5rem;
  }
}
.search-field-outer .ant-form-item-control-input {
  min-height: 28px;
  margin-top: 0px;
}
.ant-form-item.search-field-outer {
  margin-bottom: -4px !important;
}
.active-btn-outer .grey-btn .anticon {
  font-size: 10px;
}
.master-search-outer {
  width: calc(100% - 333px);
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
}
.master-justify-end .master-search-outer {
  width: 100%;
  max-width: 1533px;
}

.hide-empty-rows .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarColor) #C2D2E4;
  padding-right: 0px;
  margin-right: -7px;
}
.hide-empty-rows .ant-table-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px; 
  background: #C4C4C4;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hide-empty-rows .ant-table-body::-webkit-scrollbar-thumb {
  /* border-radius: 4px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--scrollbarColor);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.hide-empty-rows .ant-table-header th.ant-table-cell.ant-table-cell-fix-right:last-child {
  right: 0 !important;
}
.hide-empty-rows .ant-table-header th.ant-table-cell.ant-table-cell-fix-right:not(.ant-table-cell-scrollbar):not(.scrollRight) {
  right: 0 !important;
}
.hide-empty-rows .ant-table-header th.ant-table-cell.ant-table-cell-scrollbar {
  background: transparent;
  border: none;
}
.multi-select-width .ant-table.ant-table-bordered tbody > tr > td:first-child{
  width: 63px;
}

tr[data-row-key="DND"] > td {
  color: transparent !important;
  font-size: 0 !important;
}
tr[data-row-key="DND"] > td > * {
  display: none;
}

.master-table .ant-table-body{
  /* height: 100vh; */
  /* overflow-y: scroll; */
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbarColor) #C2D2E4;
  padding-right: 0px;
  margin-right: -7px;
}
.master-table .ant-table-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px; 
  background: #C4C4C4;
  /* border-radius: 5px; */
}
.master-table .ant-table-header th.ant-table-cell.ant-table-selection-column, 
.master-table .ant-table-body td.ant-table-cell.ant-table-selection-column {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}
.master-table .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--scrollbarColor);
}
.master-table .ant-table-header th.ant-table-cell.ant-table-cell-fix-right {
  right: 0 !important;
}
.master-table .ant-table-header th.ant-table-cell.ant-table-cell-scrollbar {
  background: transparent;
  border: none;
}

.master-table tr:not([data-row-key]).ant-table-row td > * {
  display: none;
}
.master-table tr:not([data-row-key]).ant-table-row td {
  font-size: 0;
}
.master-table table tr.ant-table-row td .ant-select,
.master-table table tr.ant-table-row td .ant-input-number {
  max-width: 100%;
}
.pqq-proj-list {
  padding: 20px 24px 10px 9px;
}
.master-search-outer.project-search-outer {
  width: 100%;
}
.project-management .master-col {
  padding: 0 23px !important;
}
/* formula builder start */
.formula-input-end{
  display: flex;
  align-items: flex-end;
}
.formula-input-inline{
  display: flex;
  align-items: flex-end;
  width: 96%;
}
.formula-input-item{
  display: inline-block;
  width: 20%;
  margin-right: 5px;
}
  /* formula scroll start */
  .formula-scroll{
    height: 80vh;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .formula-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  
  .formula-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbarColor);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  /* formula scroll end */
/* formula builder end */
.item-baseline{align-items: baseline !important;}
.ant-tabs-bar-none .ant-tabs-bar{border-bottom:none}
@media (min-width: 768px) {
  .master-form-style .ant-space-align-center{
    float: right;
  }
}
@media (max-width: 991px) {
.ant-input-search.search-field.ant-input-affix-wrapper {
  max-width: 90% !important;
}
.ant-input-search.search-field.ant-input-group-wrapper {
  max-width: 90% !important;
}
.master-col .tab-pannel-alignment {
  padding: 0 24px 0 10px;
}
.master-col-350 {
  min-width: 200px;
  width: 25%;
}
.master-col {
  width: calc(100% - 200px);
}
.master-table .ant-table-body {
  padding-right: 0px;
  margin-right: -7px;
}
.master-proj-list {
  width: 36%;
}
.master-search-outer {
  width: 64%;
}
}
@media (min-width: 1780px) {
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    font-size: 16px;
 }
 .master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
  padding: 7px 18px;
 }
/*  .ant-tabs.master-data-tabs.ant-tabs-card {
  margin-top: -30px;
 } */
 .formula-input-inline{
   width: 100%;
 }
}
/* @media (min-width: 1780px) {
  .master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
    padding: 7px 25px;
  }
} */
@media screen and (max-width: 1400px) and (min-width: 1366px) {
  .master-data-tabs.ant-tabs.ant-tabs-card .ant-tabs-tab {
    padding: 6px 8px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .master-col-350 {
    min-width: 286px;
    max-width: 286px;
  }
  .master-proj-list {
    width: 266px;
  }
  .master-col {
    width: calc(100% - 286px);
  }
  .master-search-outer {
    width: calc(100% - 266px);
  }
}
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}