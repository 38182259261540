.profile-img {
  border-radius: 50%;
  height: 200px;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.role-permission-header {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--probluedarker);
  margin-bottom: 0px;
}

.role-permissions-card {
  background: #F7FDFE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 20px;
}

.roles-per-card-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3370A8;
  padding-left: 15px;
}

.select-input-style {
  width: 229px !important;
  height: 28px;
}

.ant-select-selector {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px !important;
}

.options-style {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3370A8;
}

.ant-checkbox-inner {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
}

.checkbox-label-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.checkbox-mb {
  margin-bottom: 15px;
}

.btn-success {
  background: #63AA4A;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.operations-data-tabs .ant-tabs-bar {
  margin: 0 0 0;
}

.operations-data-tabs.ant-tabs .ant-tabs-nav>div {
  display: flex;
}

.permissionsTabs.ant-tabs .ant-tabs-nav>div {
  flex-wrap: wrap;
}

/* .user-management .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab{
    border-bottom: 3px solid var(--probluedarker);
  } */
.user-management .ant-tabs-nav-wrap .ant-tabs-tab:first-child {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.user-management .ant-tabs-nav-wrap .ant-tabs-tab:last-child {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.user-management .ant-tabs-nav-wrap {
  display: flex !important;
  justify-content: flex-end !important;
}

.tab-header-center .ant-tabs-nav-wrap {
  justify-content: center;
}

.tabs-header-end .ant-tabs-nav-wrap {
  justify-content: flex-end !important;
}

.tabs-header-start .ant-tabs-nav-wrap {
  justify-content: flex-start !important;
}

.pill-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  min-width: 80px;
  height: 24px;
}

.pill-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 4px 10px;
  width: auto;
  margin-right: 0;
  box-shadow: none;
}

.pill-tabs.ant-tabs-card .ant-tabs-nav-scroll {
  text-align: center;
  box-shadow: 0 0.17em 0.17em rgb(0 0 0 / 25%);
  margin: 10px 17px 10px 10px;
  z-index: 2;
}

.pill-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: rgb(0 0 0/.1);
  left: -1px;
}

.user-verification-tab .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab {
  border-bottom: none;
  margin: -1px 0px 7px 0 !important;
}

.user-verification-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.user-verification-tab .ant-tabs-nav-wrap {
  border-top: none;
}

.user-management-tab-bg {
  background: rgba(240, 239, 239, 0.5);
}

.blue-block-btn {
  background: #E7ECEE;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--probluedarker);
}

.user-verification-tab .ant-tabs-top-bar {
  float: right;
}

.verify-style {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #5F5A60;

  background: #F1F0F0;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 5px 10px 5px;
}

.permissionsTabs.ant-tabs .ant-tabs-tab {
  background: rgba(231, 236, 238, 0.8);
  text-align: left;
  padding: 12px 24px 11px 34px;
  border: none !important;
  color: var(--probluedarker);
  font-weight: 600;
  width: 100% !important;
  text-align: left !important;
  margin-bottom: 12px;
}

.permissionsTabs .ant-tabs-tab::before {
  width: 4px;
  height: 4px;
  background: #3C7AB4;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: none;
}

.permissionsTabs .ant-tabs-nav-wrap {
  border: none;
}

.permissionsTabs.ant-tabs .ant-tabs-ink-bar,
.permissionsTabs.ant-tabs .ant-tabs-tab-next,
.permissionsTabs.ant-tabs .ant-tabs-tab-prev {
  display: none !important;
}

.permissionsTabs.ant-tabs .ant-tabs-bar.ant-tabs-left-bar {
  border: none !important;
  width: 42%;
  min-width: 235px;
  max-width: 365px;
}

.permissionsTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: rgba(51, 112, 168, 0.8);
  color: white;
  overflow: visible;
}

.permissionsTabs .ant-tabs-tab.ant-tabs-tab-active::before {
  background: white;
}

.permissionsTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active::after {
  content: "";
  background: #5a8bb7;
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -49px;
  display: block;
}

.permissionsTabs.ant-tabs .ant-tabs-nav-container,
.permissionsTabs.ant-tabs .ant-tabs-nav-wrap,
.permissionsTabs.ant-tabs .ant-tabs-nav-scroll,
.permissionsTabs.ant-tabs .ant-tabs-nav,
.permissionsTabs.ant-tabs .ant-tabs-nav>div {
  overflow: visible;
}

.permissionsTabs.ant-tabs .ant-tabs-content {
  height: calc(100% - 40px);
  width: calc(62% - 23px);
  padding: 50px 58px;
  margin-left: 23px;
  background: rgba(231, 236, 238, 0.8);
  max-width: 365px;
  display: flex;
  align-items: center;
}

.permissionsTabs.ant-tabs .ant-tabs-content .ant-tabs-tabpane {
  display: none;
}

.permissionsTabs.ant-tabs .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: block;
}

.ant-tabs.permissionsTabs {
  display: flex;
  height: 100%;
}

.permissionsTabs .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab {
  max-width: 365px;
}

.dc-permissions-collapse .ant-collapse-content-box {
  padding: 0px;
}

/* green tick mark start */
.dc-green-tick .ant-checkbox-inner {
  background-color: unset;
  border: none;
  box-shadow: none;
  width: 20px !important;
  height: 20px !important;
}

.dc-green-tick .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #189780 !important;
  width: 6.714286px;
  height: 14.142857px;
}

/* green tick mark end */
.dc-permissions-form-top {
  position: absolute;
  top: -32px;
  width: 85%;
}

.reset-pill-tabs .pill-reset-2 .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
  margin: 10px 0 0 0 !important;
  padding: unset !important;
  box-shadow: none !important;
}

.reset-pill-tabs .pill-reset-2 .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab {
  margin-right: 6px !important;
}

.dc-hide-from-view .ant-select-selection-search {
  width: 100% !important;
}