.mtb-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.slick-slider.verSlider-inner.slick-vertical {
    /* box-shadow: inset -15px 0px 30px rgba(0, 0, 0, 0.03); */
    width: 220px;
    height: 542px;
    position: relative;
    border-radius: 0 50% 50% 0;
    display: flex;
    align-items: center;
}
.slick-slider.verSlider-inner.slick-vertical:not(.verSlider-small) .slick-list {
    height: 542px !important;
}
.slick-slider.verSlider-inner.slick-vertical .slick-list {
    padding: 0 !important;
    display: flex;
    /* overflow-y: auto; */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    width: 100%;
}
.slick-slider.verSlider-inner.slick-vertical .slick-list::-webkit-scrollbar {
    display: none;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-10 .slick-list {
    height: 542px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-9 .slick-list {
    height: 442px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-8 .slick-list {
    height: 442px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-7 .slick-list {
    height: 342px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-6 .slick-list {
    height: 342px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-5 .slick-list {
    height: 242px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-4 .slick-list {
    height: 242px !important;
    padding-top: 50px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-3 .slick-list,
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-1 .slick-list {
    height: 142px !important;
}
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-2 .slick-list,
.slick-slider.verSlider-inner.slick-vertical.verSlider-small-0 .slick-list {
    height: 142px !important;
    padding-top: 50px !important;
}
.slick-slider .slick-track {
display: flex;
align-items: center;
justify-content: space-around;
/* flex-direction: column; */
flex-wrap: wrap;
margin: auto;
}
.verSlider-outer {
    width: 220px;
    margin-left: -15px;
    padding: 50px 15px;
    position: relative;
    background: url('../assets/images/CurveSmall.svg') no-repeat;
    background-size: contain;
    background-position: 0px center;
}
.slick-slide span {
    color: #857E7E;
    padding: 5px 0;
    display: block;
    font-size: 15px;
    /* text-decoration: underline; */
    max-width: 126px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all 0.3s ease;
}
.slick-slide.slick-active.slick-current span {
    font-size: 21px;
    color: #3471A9;
    font-weight: 600;
    max-width: 175px;
}
.verSlider-curve {
    /* box-shadow: inset -6px 0px 10px rgba(0, 0, 0, 0.25); */
    border-radius: 0 50% 50% 0;
    /* padding: 50px 10px; */
    /* background: white; */
}
.slick-arrow.slick-prev {
    top: -35px;
}
.slick-arrow {
    position: absolute;
    left: 10px;
    transform: translateX(-50%) rotate(-90deg);
}
.slick-arrow::before {
content: "\276F";
color: #5F5A60;
opacity: 1;
}
.slick-arrow.slick-next {
    top: auto;
    bottom: -35px;
    transform: translateX(-50%) rotate(90deg);
}
.verSlider-sectn {
    position: fixed;
    z-index: 10;
    margin-left: -30px;
}
.project-details-outer {
    /* margin: 0 0 0 -75px; */
    text-align: right;
}
.project-details-inner {
    background: rgb(231 235 237 / 0.5);
    margin-bottom: 18px;
    padding-bottom: 15px;
}
/* .verSlider-sectn::before {
    content: "";
    position: fixed;
    background: linear-gradient(180deg, transparent 0, transparent 30%, white 31%, white);
    /* background-image: url('../Assets/images/curve.svg'); */
    /* box-shadow: inset -15px 0px 30px rgba(0, 0, 0, 0.03);
    width: 240px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1; */
    /* border-radius: 0 50% 50% 0; */
    /* border: 7px solid white; */
/* }  */
/* .verSlider-sectn:before {
    content: "";
    position: fixed;
    background-image: url('../Assets/imgs/solar-system.svg');
    background-repeat: no-repeat;
    background: linear-gradient(180deg,transparent,transparent 30%,#fff 31%,#fff);
    box-shadow: inset -15px 0 30px rgba(0,0,0,.03);
    width: 2000px;
    height: 200%;
    top: 50%;
    left: -1760px;
    transform: translateY(-50%);
    z-index: -1;
    border-radius: 0 50% 50% 0;
    border: 7px solid #fff;
} */
.verSlider-outer::before {
    content: "";
    position: fixed;
    width: 245px;
    left: -5px;
    height: 100%;
    top: 0;
    z-index: -1;
    background: url('../assets/images/CurveBig.svg') no-repeat;
    /* background-size: cover; */
    background-position: 0px 0px;
    
}
.sidebarMenuTitle {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F5A60;
    margin: 30px 0 15px 38px;
}
.details-btn{
    background: var(--probluedarker);
    color: white;
    /* padding: 3px 20px 4px 60px; */
    border-radius: 5px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 15px;
    height: 45px;
    width: 111px;
}
.titleTop {
    background: #F0EFEF;
    color: #5F5A60;
    padding: 11px 10px;
    border-radius: 5px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25), 0 0 0px 10px white;
    margin: 40px 0;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    display: none;
    width: 100px;
    margin-left: auto !important;
}
.ant-tabs-tab-active .titleTop {
    background: var(--probluedarker);
    color: white;
}
.project-details-inner span.badge {
    width: 20px;
    border-radius: 50%;
    background: #CFD8DC;
    height: 20px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.project-details-inner::after {
    clear: both;
    display: block;
    content: "";
}
.project-details-outer h3.titlebottom {
    background: #F0EFEF;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: #5F5A60;
}

.verSliderDiv {
    width: 190px;
}
.verSliderSubDiv {
    width: 195px;
    margin: 0 20px 0 -130px;
}
.verSliderContent {
    width: calc(100% - 190px);
}
.pqf-header-outer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}
.tabs-style .ant-tabs-tab:nth-child(1) {
    border-radius: 5px 0px 0px 0px;
    margin-right: 5px;
}
.tabs-style .ant-tabs-tab:nth-child(2) {
    border-radius: 0px 5px 0px 0px;
}
.tabs-style .ant-tabs-tab {
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    font-size: 15px;
    padding: 5px 18px 7px;
    line-height: 1.2;
    color: #5F5A60;
    ;
}
.tabs-style .ant-tabs-tab.ant-tabs-tab-active {
    background: #3370A8;
    color: white;
}
.tabs-style .ant-tabs-ink-bar {
    display: none !important;
}
label.pqf-formTitle {
    display: block;
    text-align: right;
    position: relative;
    margin-bottom: 15px;
}
label.pqf-formTitle span {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5F5A60;
    line-height: 1.4;
    position: relative;
    background: rgb(240 239 239);
    padding: 2px 10px;
}
label.pqf-formTitle::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #5F5A60;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.guestroomOuter {
    background: #F3F5F6;
    border-radius: 6px;
}
.verticalLayout-form .ant-col.ant-form-item-label {
    width: 100%;
}
.verticalLayout-form textarea {
    resize: none;
}
.ant-col.guestroomOuter {
    padding: 50px 45px 50px 25px !important;
}
.ant-tabs.ant-tabs-left.menuTabs {
    overflow: visible;
}

.ant-tabs.menuTabs .subMenuTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #5F5A60;
    margin: 16px 11px 16px auto;
    display: block;
    width: 75px;
}
.ant-tabs.menuTabs .ant-tabs-bar.ant-tabs-left-bar {
    background: rgba(231, 235, 237, 0.5);
    width: 95px;
    margin-left: -8px;
    /* margin-top: 70px; */
    /* padding: 8px 0px; */
    border-radius: 3px;
}
.ant-tabs.menuTabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
    padding: 0;
    margin-bottom: 18px; 
}
.ant-tabs.menuTabs .subMenu-tab {
    font-size: 15px;
    color: #5F5A60;
    font-weight: 700;
    padding: 6px 5px;
    width: 70px;
    background: #F0EFEF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 15px 12px 15px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-tabs.menuTabs .ant-tabs-ink-bar {
    display: none !important;
}
.ant-tabs.menuTabs .ant-tabs-tab.ant-tabs-tab-active .subMenu-tab {
    background: var(--problue);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: white;
}
/* .ant-tabs.ant-tabs-left.FFE-open.menuTabs > .ant-tabs-bar {background: linear-gradient(180deg, rgba(125, 169, 209, 0.3) 50%, rgba(231, 235, 237, 0.5) 50%, rgba(231, 235, 237, 0.5) );} */
/* .ant-tabs.ant-tabs-left.OSE-open.menuTabs .ant-tabs-bar {background: linear-gradient(180deg, rgba(231, 235, 237, 0.5) 50%, rgba(125, 169, 209, 0.3) 50%, rgba(125, 169, 209, 0.3) );} */
.slick-vertical .slick-slide {height: 50px;transition: all 0.3s ease;}
.tabName {
    position: absolute;
    margin-top: 45px;
    font-size: 15px;
    font-weight: 600;
    width: 185px;
    text-align: right;
    color: #5F5A60;
    margin-left: -83px;
}
@media screen and (max-width: 1440px) {
    .verSlider-outer {margin-left: 15px;}
    /* .ant-tabs.menuTabs .ant-tabs-bar.ant-tabs-left-bar {margin-left: -45px;} */
}

@media screen and (max-height: 870px) {
    header.ant-layout-header .header-logo {
      max-width: 190px;
      margin-top: -30px;
    }
/*     .fixed-height-pannel-scroll .ant-tabs-tabpane {
      height: calc(100vh - 175px);
    }
    .fixed-height-pannel-scroll .ant-tabs-tabpane .ant-tabs-tabpane-active {
        height: auto;
    } */
    .verSlider-sectn .sidebarMenuTitle {margin: 0px 0 15px 38px;}
    /* .ant-tabs.menuTabs div.ant-tabs-bar.ant-tabs-left-bar {margin-top: 40px;} */
    .verSlider-sectn .verSlider-outer::before {background-position: 0px -60px;left: -10px;}
    /* .layout-style {padding: 0px 0px 0px;} */
  }
  @media screen and (max-height: 800px) {
    .layout-style {padding: 0px 0px 0px;}
    header.ant-layout-header .header-logo {
      margin-top: -55px;
      max-width: 146px;
      margin-left: 130px;
    }
    .verSlider-outer::before {
      background-position: top -45px center;
    }
    main.ant-layout-content.main-content-area {
      padding: 0px 16px 0 !important;
    }
    .verSliderContent .ant-tabs.menuTabs .subMenuTitle {margin: 9px 11px 7px auto;}
/*     .fixed-height-pannel-scroll .ant-tabs-tabpane {
      height: calc(97vh - 120px);
    }
    .fixed-height-pannel-scroll .ant-tabs-tabpane .ant-tabs-tabpane-active {
        height: auto;
    } */
    .verSliderContent .ant-tabs.menuTabs .ant-tabs-bar.ant-tabs-left-bar {
      padding: 0px;
    }
    .verSliderContent .ant-tabs.menuTabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav > div > .ant-tabs-tab {
      margin-bottom: 7px;
    }
    .verSliderContent .ant-tabs.menuTabs .subMenu-tab {
      margin: 8px 11px 15px auto;padding: 5px 5px 2px;
    }
    .verSliderContent > .ant-tabs > .ant-tabs-content {
      padding-left: 13px;
    }
    .verSlider-sectn .verSlider-outer::before {
      background-position: 0px -212px;
      top: 0px;
      left: -45px;
    }
    .slick-slide span {
      text-decoration: none;
    }
    .slick-vertical .slick-track .slick-slide {
      height: 42px;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical:not(.verSlider-small) .slick-list {
      height: 462px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical {
      height: 462px;
    }
    .verSlider-curve .slick-arrow.slick-prev {
      top: -20px;
    }
    .verSlider-curve .slick-arrow.slick-next {
      bottom: -20px;
    }
    .verSlider-sectn .verSlider-outer {
      padding: 35px 15px;
      background-image: url("../assets/images/CurveSmallTab.svg");
    }
    .verSlider-sectn .sidebarMenuTitle {
      margin: 0px 0 10px 33px;
      font-size: 15px;
      line-height: 19px;
    }
    .verSliderDiv {
      margin-top: -35px;
    }
    .verSlider-outer .slick-slide span {
      text-decoration: none;
    }
    .ant-tabs.menuTabs div.ant-tabs-bar.ant-tabs-left-bar {
      margin-top: -5px;
      /* margin-left: -85px; */
    }
    .verSliderContent .titleTop {
      margin: 36px 0;
    }
    
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-10 .slick-list {
        height: 462px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-9 .slick-list {
        height: 378px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-8 .slick-list {
        height: 378px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-7 .slick-list {
        height: 294px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-6 .slick-list {
        height: 294px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-5 .slick-list {
        height: 210px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-4 .slick-list {
        height: 210px !important;
        padding-top: 42px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-3 .slick-list,
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-1 .slick-list {
        height: 126px !important;
    }
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-2 .slick-list,
    .verSlider-curve .slick-slider.verSlider-inner.slick-vertical.verSlider-small-0 .slick-list {
        height: 126px !important;
        padding-top: 42px !important;
    }
    .slick-slide span {
        max-width: 117px;
    }
    .slick-slide.slick-active.slick-current span {
        max-width: 155px;
    }    
    li.ant-menu-item.gap-left {
        padding-left: 28px;
    }
    li.ant-menu-item.gap-right {
        padding-right: 28px;
    }
  }
  @media screen and (min-width: 1441px) and (max-height: 800px) {
    .verSlider-sectn .verSlider-outer {margin-left: 15px;}
  }
  
  